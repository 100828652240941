import { message } from "antd";
import axios from "axios";
import { SYSTEM_ERROR } from "config/CONSTANTS";

export function postRequest(url, param, callback) {
  return axios
    .post(url, param)
    .then(function (response) {
      const { data } = response;
      if (data.status === false) {
        message.error(data.message);
        callback(null);
      } else {
        callback(response.data);
      }
      return response.data;
    })
    .catch(function (error) {
      callback(null);
      return null;
    });
}
export function putRequest(url, param, callback) {
  return axios
    .put(url, param)
    .then(function (response) {
      const { data } = response;
      if (data.status === false) {
        message.error(data.message);
        callback(null);
      } else {
        callback(response.data);
      }
      return response.data;
    })
    .catch(function (error) {
      callback(null);
      return null;
    });
}
export function getRequest(url, callback) {
  axios
    .get(url)
    .then(function (response) {
      const { data } = response;
      if (data.status === false) {
        message.error(data.message);
        callback(null);
      } else {
        callback(response.data);
      }
    })
    .catch(function (error) {
      callback(null);
    });
}

export function deleteRequest(url, callback) {
  axios
    .delete(url)
    .then(function (response) {
      const { data } = response;
      if (data.status === false) {
        message.error(data.message);
        callback(null);
      } else {
        callback(response.data);
      }
    })
    .catch(function (error) {
      callback(null);
    });
}
export function deleteRequestWithParam(url, param, callback) {
  axios
    .delete(url, { data: param })
    .then(function (response) {
      const { data } = response;
      if (data.status === false) {
        message.error(data.message);
        callback(null);
      } else {
        callback(response.data);
      }
    })
    .catch(function (error) {
      callback(null);
    });
}

export const axiosPost = (url, param, errorMessage = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(url, param)
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject(errorMessage, err);
        });
    } catch (error) {
      console.error(errorMessage, error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const axiosPostMultiPart = (url, param, errorMessage = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(url, param, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject(errorMessage, err);
        });
    } catch (error) {
      console.error(errorMessage, error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const axiosPut = (url, param, errorMessage = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(url, param)
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject(errorMessage, err);
        });
    } catch (error) {
      console.error(errorMessage, error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const axiosGet = (url, param, errorMessage = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(url, { params: param })
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject(errorMessage, err);
        });
    } catch (error) {
      console.error(errorMessage, error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const axiosDelete = (url, param, errorMessage = "") => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(url, { data: param })
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject(errorMessage, err);
        });
    } catch (error) {
      console.error(errorMessage, error);
      reject(SYSTEM_ERROR);
    }
  });
};
