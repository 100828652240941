// You can use CONSTANTS.js file for below definitions of constants and import here.
export const SIZE_CHANGED = "SIZE_CHANGED";
export const MENU_COLLAPSED_CHANGED = "MENU_COLLAPSED_CHANGED";
export const CURRENT_ROUTE = "CURRENT_ROOT";
export const UNREAD_NOTIFICATION_COUNT = "UNREAD_NOTIFICATION_COUNT";
export const STORE_LIST = "STORE_LIST";
export const PAYTOPROMOTE_LIST = "PAYTOPROMOTE_LIST";
export const NOPROMOTE_LIST = "NOPROMOTE_LIST";
export const PRODUCT_BASIC_DATA = "PRODUCT_BASIC_DATA";

export const ChangeSize = (payload) => ({
  type: SIZE_CHANGED,
  payload
})

export const ChangeMenuCollapsed = (payload) => ({
  type: MENU_COLLAPSED_CHANGED,
  payload
})

export const UpdateCurrentRoot = (payload) => ({
  type: CURRENT_ROUTE,
  payload
})
export const UpdateUnreadNotificationCount = (payload) => ({
  type: UNREAD_NOTIFICATION_COUNT,
  payload
})
export const UpdateStoreList = (payload) => ({
  type: STORE_LIST,
  payload
})
export const UpdatePaytoPromoteList = (payload) => ({
  type: PAYTOPROMOTE_LIST,
  payload
})
export const UpdateNoPromoteList = (payload) => ({
  type: NOPROMOTE_LIST,
  payload
})
export const UpdateProductBasicData = (payload) => ({
  type: PRODUCT_BASIC_DATA,
  payload
})