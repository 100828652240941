// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

export const googleKey = "AIzaSyCmk2De3Niv-FCozvHmLJ8MdzhspZQ8PLM";
export const REGISTER_WITH = {
    email: "Email",
    phone: "Phone"
}

