import React from "react";
import { Button, message, Space } from "antd";
import OneTable from "./OneTable";
import "./OnePage.css";

const OnePage = (props) => {
  const {
    dataList,
    setDataList,
    onClickDelete,
    onUpdateStory,
    setIsBusy,
    onClickReload,
    keyword,
    setKeyword,
  } = props;

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (v, item, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Cat Type",
      dataIndex: "cat_type",
      key: "cat_type",
    },
    {
      title: "Cat",
      key: "cat",
      render: (v, item) => {
        return item.cat.it;
      },
    },
    {
      title: "SubCat",
      key: "subcat",
      render: (v, item) => {
        return item.subcat.it;
      },
    },
    {
      title: "Address",
      key: "address",
      render: (v, item) => {
        return item.location.address.street + ", " + item.location.address.city;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Website",
      dataIndex: "web",
      key: "web",
    },
    {
      title: "Action",
      width: "200px",
      key: "action",
      render: (v, item) => {
        return (
          <Space direction={"horizontal"}>
            <Button
              type={"primary"}
              shape="round"
              size={"small"}
              onClick={() => onUpdateStory(item)}
            >
              Edit
            </Button>
            <Button
              type={"ghost"}
              shape="round"
              size={"small"}
              onClick={() => onClickDelete(item.id)}
              style={{ color: "var(--redColor)" }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <OneTable
        onClickReload={onClickReload}
        dataList={dataList.map((x, index) => ({
          ...x,
          key: index,
        }))}
        keyword={keyword}
        setKeyword={setKeyword}
        columns={columns}
      />
    </div>
  );
};

export default OnePage;
