import { axiosGet, axiosPost, axiosPostMultiPart } from "./ajaxServices";
import { urlAddExtraSound, urlAddStory, urlDeleteStory, urlGetProfileInfo, urlGetProjectInfo, urlStoryDetailInfo, urlStoryList, urlUpdateExtraSound, urlUpdateProjectInfo, urlUpdateStory, urlUpdateStoryDetail } from "./CONSTANTS";

export const apiGetStoryList = (type) => {
  const url = urlStoryList;
  let param = { type: type };
  return new Promise((resolve, reject) => {
    axiosGet(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetStoryDetailInfo = (type) => {
  const url = urlStoryDetailInfo;
  let param = { type: type };
  return new Promise((resolve, reject) => {
    axiosGet(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function apiUpdateStory(storyInfo) {
  const url = urlUpdateStory;
  var formData = new FormData();
  formData.append("id", storyInfo.id);
  formData.append("type", storyInfo.type);
  formData.append("name", storyInfo.name);
  formData.append("short_name", storyInfo.short_name);
  formData.append("description_en", storyInfo.description_en);
  formData.append("description_it", storyInfo.description_it);
  if (storyInfo.speaker != undefined) {
    if (storyInfo.speaker instanceof File) {
      formData.append("speaker", storyInfo.speaker);
    }
  }
  if (storyInfo.profile != undefined) {
    if (storyInfo.profile instanceof File) {
      formData.append("profile", storyInfo.profile);
    }
  }
  if (storyInfo.soundFile != undefined) {
    if (storyInfo.soundFile instanceof File) {
      formData.append("soundFile", storyInfo.soundFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function apiAddStory(storyInfo) {
  const url = urlAddStory;
  var formData = new FormData();
  formData.append("id", storyInfo.id);
  formData.append("type", storyInfo.type);
  formData.append("name", storyInfo.name);
  formData.append("short_name", storyInfo.short_name);
  formData.append("description_en", storyInfo.description_en);
  formData.append("description_it", storyInfo.description_it);
  if (storyInfo.speaker != undefined) {
    if (storyInfo.speaker instanceof File) {
      formData.append("speaker", storyInfo.speaker);
    }
  }
  if (storyInfo.profile != undefined) {
    if (storyInfo.profile instanceof File) {
      formData.append("profile", storyInfo.profile);
    }
  }
  if (storyInfo.soundFile != undefined) {
    if (storyInfo.soundFile instanceof File) {
      formData.append("soundFile", storyInfo.soundFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiUpdateExtraSound(storyInfo) {
  const url = urlUpdateExtraSound;
  var formData = new FormData();
  formData.append("id", storyInfo.id);
  formData.append("type", storyInfo.type);
  formData.append("name", storyInfo.name);
  formData.append("short_name", storyInfo.short_name);
  if (storyInfo.soundFile != undefined) {
    if (storyInfo.soundFile instanceof File) {
      formData.append("soundFile", storyInfo.soundFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function apiAddExtraSound(storyInfo) {
  const url = urlAddExtraSound;
  var formData = new FormData();
  formData.append("type", storyInfo.type);
  formData.append("name", storyInfo.name);
  formData.append("short_name", storyInfo.short_name);
  if (storyInfo.soundFile != undefined) {
    if (storyInfo.soundFile instanceof File) {
      formData.append("soundFile", storyInfo.soundFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function apiUpdateStoryDetail(storyInfo) {
  const url = urlUpdateStoryDetail;
  var formData = new FormData();
  formData.append("type", storyInfo.type);
  formData.append("name", storyInfo.name);
  formData.append("short_name", storyInfo.short_name);
  formData.append("description_en", storyInfo.description_en);
  formData.append("description_it", storyInfo.description_it);
  formData.append("bodyguard_title_en", storyInfo.bodyguard_title_en);
  formData.append("bodyguard_title_it", storyInfo.bodyguard_title_it);
  if (storyInfo.speakerFile != undefined) {
    if (storyInfo.speakerFile instanceof File) {
      formData.append("speakerFile", storyInfo.speakerFile);
    }
  }
  if (storyInfo.profileFile != undefined) {
    if (storyInfo.profileFile instanceof File) {
      formData.append("profileFile", storyInfo.profileFile);
    }
  }
  if (storyInfo.soundFile != undefined) {
    if (storyInfo.soundFile instanceof File) {
      formData.append("soundFile", storyInfo.soundFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const apiDeleteStory = (id) => {
  const url = urlDeleteStory;
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetProjectInfo = () => {
  const url = urlGetProjectInfo;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function apiUpdateProjectDetail(storyInfo) {
  const url = urlUpdateProjectInfo;
  var formData = new FormData();
  formData.append("type", storyInfo.type);
  formData.append("title_en", storyInfo.title_en);
  formData.append("title_it", storyInfo.title_it);
  formData.append("description_en", storyInfo.description_en);
  formData.append("description_it", storyInfo.description_it);
  formData.append("content_en", storyInfo.content_en);
  formData.append("content_it", storyInfo.content_it);
  formData.append("credit1_en", storyInfo.credit1_en);
  formData.append("credit1_it", storyInfo.credit1_it);
  formData.append("credit2_en", storyInfo.credit2_en);
  formData.append("credit2_it", storyInfo.credit2_it);
  formData.append("credit3_en", storyInfo.credit3_en);
  formData.append("credit3_it", storyInfo.credit3_it);
  if (storyInfo.speakerFile1 != undefined) {
    if (storyInfo.speakerFile1 instanceof File) {
      formData.append("speakerFile1", storyInfo.speakerFile1);
    }
  }
  if (storyInfo.speakerFile2 != undefined) {
    if (storyInfo.speakerFile2 instanceof File) {
      formData.append("speakerFile2", storyInfo.speakerFile2);
    }
  }
  if (storyInfo.speakerFile3 != undefined) {
    if (storyInfo.speakerFile3 instanceof File) {
      formData.append("speakerFile3", storyInfo.speakerFile3);
    }
  }
  if (storyInfo.speakerFile4 != undefined) {
    if (storyInfo.speakerFile4 instanceof File) {
      formData.append("speakerFile4", storyInfo.speakerFile4);
    }
  }
  if (storyInfo.speakerFile5 != undefined) {
    if (storyInfo.speakerFile5 instanceof File) {
      formData.append("speakerFile5", storyInfo.speakerFile5);
    }
  }
  if (storyInfo.profileFile != undefined) {
    if (storyInfo.profileFile instanceof File) {
      formData.append("profileFile", storyInfo.profileFile);
    }
  }
  if (storyInfo.mediaFile != undefined) {
    if (storyInfo.mediaFile instanceof File) {
      formData.append("mediaFile", storyInfo.mediaFile);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
