import React from "react";
import { useState } from "react";
import { Row, Col, Tabs, Spin, message, Input } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./PizoPage.css";
import OnePage from "./OnePage/OnePage";
import { apiGetStoryList, apiUpdateStory } from "services/storyService";
import { useEffect } from "react";
import { showDetailModal } from "./DetailModal/showDetailModal";
import {
  apiAddPizzo,
  apiDeletePizzo,
  apiGetPizzoCatList,
  apiGetPizzoList,
  apiUpdatePizzo,
} from "services/pizzoService";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ContentSection from "./ContentSection/ContentSection";
const { Search } = Input;
const { TabPane } = Tabs;
function PizoPage(props) {
  const [srcList, setSrcList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [contentInfo, setContentInfo] = useState(null);

  useEffect(() => {
    getCategoryList();
    getStoryList();
  }, []);
  const getStoryList = () => {
    setIsBusy(true);
    apiGetPizzoList()
      .then((res) => {
        setSrcList(res.list);
        setDataList(res.list);
        setContentInfo(res.detail);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onUpdateStory = async (item) => {
    if (categoryList.length == 0) {
      message.error("Category doesn't exist");
    } else {
      let tmpList = [...categoryList];
      let fList = tmpList.filter(
        (x) => x.cat_type == item.cat_type && x.subcat == item.subcat_key
      );
      if (fList.length > 0) {
        let cat_id = fList[0].id;
        let dlgRes = await showDetailModal({
          pizzoItem: { ...item, category: cat_id },
          edit: true,
          categoryList: categoryList,
        });
        if (dlgRes == null) return;
        let tmpCList = [...categoryList];
        let catItem = tmpCList.find((x) => x.id == dlgRes.category);
        let param = {
          ...dlgRes,
          cat: catItem.cat,
          cat_type: catItem.cat_type,
          subcat: catItem.subcat,
          city: dlgRes.stCity
        };
        setIsBusy(true);
        apiUpdatePizzo(param)
          .then((res) => {
            setIsBusy(false);
            // let tmpList = [...dataList];
            // let oneItemIndex = tmpList.findIndex((x) => x.id == dlgRes.id);
            // tmpList[oneItemIndex] = { ...dlgRes, subcat_key: catItem.subcat };
            // setDataList(tmpList);

            // let tmpSrcList = [...srcList];
            // let oneSrcItemIndex = tmpSrcList.findIndex(
            //   (x) => x.id == dlgRes.id
            // );
            // tmpList[oneSrcItemIndex] = {
            //   ...dlgRes,
            //   subcat_key: catItem.subcat,
            // };
            // setSrcList(tmpSrcList);
            getStoryList();
            message.success("Updated successfully.");
          })
          .catch((err) => {
            setIsBusy(false);
            message.error(err);
          });
      } else {
        message.error("Category doesn't exist");
      }
    }
  };
  const onClickDelete = async (id) => {
    setIsBusy(true);
    apiDeletePizzo(id)
      .then((res) => {
        message.success("Deleted successfully.");
        let tmpList = [...dataList];
        setDataList(tmpList.filter((x) => x.id != id));
        let srcTmpList = [...srcList];
        setSrcList(srcTmpList.filter((x) => x.id != id));
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onSearch = (v) => {
    let tmpList = [...srcList];
    if (v == "") {
      setDataList(tmpList);
    } else {
      setDataList(
        tmpList.filter((x) =>
          x.name.toString().toLowerCase().includes(v.toLowerCase())
        )
      );
    }
  };
  const getCategoryList = () => {
    apiGetPizzoCatList()
      .then((res) => {
        if (res != null && res.length > 0) {
          setCategoryList(
            res.map((x, index) => {
              return { ...x, id: index };
            })
          );
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onAdd = async () => {
    if (categoryList.length > 0) {
      let dlgRes = await showDetailModal({
        pizzoItem: undefined,
        edit: true,
        categoryList: categoryList,
      });
      if (dlgRes == null) return;

      let tmpCList = [...categoryList];
      let catItem = tmpCList.find((x) => x.id == dlgRes.category);
      let param = {
        ...dlgRes,
        city: dlgRes.stCity,
        cat: catItem.cat,
        cat_type: catItem.cat_type,
        subcat: catItem.subcat,
      };
      setIsBusy(true);
      apiAddPizzo(param)
        .then((res) => {
          setIsBusy(false);
          message.success("Added successfully.");
          getStoryList();
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else {
      message.error("Category doesn't exist");
    }
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Tabs type="card">
          <TabPane tab="Detail Info" key="item-1">
            <ContentSection setIsBusy={setIsBusy} contentInfo={contentInfo} />
          </TabPane>
          <TabPane tab="Item Info" key="item-2">
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col>
                <CommonButton
                  onClick={() => {
                    onAdd();
                  }}
                >
                  Add
                </CommonButton>
              </Col>
              <Col>
                <Search
                  placeholder={"Search name"}
                  allowClear
                  onSearch={(v) => {
                    onSearch(v);
                  }}
                  enterButton
                />
              </Col>
            </Row>
            <OnePage
              dataList={dataList}
              setDataList={setDataList}
              onClickDelete={onClickDelete}
              onUpdateStory={onUpdateStory}
              setIsBusy={setIsBusy}
              onClickReload={getStoryList}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </CommonDivWidget>
  );
}

export default PizoPage;
