import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Input, message } from "antd";
import { confirmable } from 'react-confirm';
import CommentItemWidget from './CommentItemWidget/CommentItemWidget';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import RatingWidget from 'components/RatingWidget/RatingWidget';
import "./CommentModal.css"

const { TextArea } = Input;

const CommentModal = (props) => {
    const { show, proceed, publishData } = props;
    const [ dataList, setDataList ] = useState([])
    const [isBusy, setIsBusy] = useState(false);
    const [ commentText, setCommentText ] = useState("")

    useEffect(()=>{
        if(publishData != undefined){
            setDataList(publishData.ReplyList)
        }
    }, [publishData])
    
    const postComment = () => {
        if(commentText == ""){
            message.error("Please input comment");
            return;
        }
        let param = { reviewId: publishData.id, review: commentText}
        
    }

    return (
        <div>
            <Modal
                visible={show}
                destroyOnClose={true}
                onCancel={() => {
                    proceed(dataList);
                }}
                closable={true}
                maskClosable={false}
                className="dashboard-comment-modal"
                title={"Comments"}
                footer={null}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="comment-detail-content">
                                <Row align={'middle'} gutter={16} className={'one-follower-comment'} wrap={false}>
                                    <Col flex={'80px'}>
                                        <img src={
                                                publishData.owner == undefined || publishData.owner.image_url == null || publishData.owner.image_url == ""
                                                ? "/assets/images/avatar_blank.png"
                                                : publishData.owner.image_url
                                            } className="modal-comment-avatar" />
                                    </Col>
                                    <Col flex={'auto'}>
                                        <div className="one-follower-comment-name">{publishData.owner.first_name} {publishData.owner.last_name}</div>
                                        <div className="one-follower-comment-rating"><RatingWidget rating={publishData.rating} /></div>
                                        <div className="one-follower-comment-comment">{publishData.review}</div>
                                    </Col>
                                </Row>
                                <div className="dashboard-comment-list-detail">
                                    {(dataList === undefined || dataList.length === 0) ? "No comments" :
                                    <CommentItemWidget
                                        reviewList={dataList}
                                        isBusy={isBusy}
                                        setIsBusy={setIsBusy}
                                    ></CommentItemWidget>} 
                                </div>
                                <TextArea
                                    placeholder="Reply to comment"
                                    rows={4}
                                    className="comment-edit"
                                    value={commentText}
                                    onChange={(e) => {
                                        setCommentText(e.target.value)
                                    }}
                                />
                                <div className="one-publish-review-reply-button">
                                    <CommonButton onClick={() => {
                                        postComment()
                                    }}>Reply</CommonButton>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
            </Modal>
        </div>
    );
};

export default confirmable(CommentModal);