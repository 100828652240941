import Icon from "@ant-design/icons";
import { ReactComponent as dashboardIcon } from "../assets/icons/ic_dashboard.svg";
import { ReactComponent as postIcon } from "../assets/icons/ic_post.svg";

export const ROOT = "/";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_STORY = "/story";
export const ROUTE_PROJECT = "/project";
export const ROUTE_PIZO = "/pizo-free";
export const ROUTE_CANZONI = "/extra-canzoni";
export const ROUTE_ISO_PRAVVISUTI = "/iso-pravvisuti";
export const ROUTE_PICCIRIDDI = "/picciriddi";
export const ROUTE_EXTRA = "/extra";
export const ROUTE_USER_PROFILE = "/user-profile";
export const ROUTE_LOGIN = "/login";

export const MENU_ITEMS = [
  {
    route: ROUTE_DASHBOARD,
    icon: <Icon component={dashboardIcon} className="menu-item-icon" />,
    title: "Overview",
    type: "sidebar",
  },
  {
    route: ROUTE_STORY,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "THE STORIES",
    type: "sidebar",
  },
  {
    route: ROUTE_PIZO,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "PIZZO-FREE",
    type: "sidebar",
  },
  {
    route: ROUTE_ISO_PRAVVISUTI,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "I SOPRAVVISUTI",
    type: "sidebar",
  },
  {
    route: ROUTE_PICCIRIDDI,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "I PICCIRIDDI",
    type: "sidebar",
  },
  {
    route: ROUTE_EXTRA,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "EXTRA",
    type: "sidebar",
  },
  {
    route: ROUTE_CANZONI,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "CANZONI",
    type: "sidebar",
  },
  {
    route: ROUTE_PROJECT,
    icon: <Icon component={postIcon} className="menu-item-icon" />,
    title: "Project",
    type: "sidebar",
  },
];

export const GetCurrentMainRoute = (pathname) => {
  if (pathname.startsWith(ROUTE_CANZONI)) return ROUTE_CANZONI;
  if (pathname.startsWith(ROUTE_DASHBOARD)) return ROUTE_DASHBOARD;
  if (pathname.startsWith(ROUTE_STORY)) return ROUTE_STORY;
  if (pathname.startsWith(ROUTE_PIZO)) return ROUTE_PIZO;
  if (pathname.startsWith(ROUTE_ISO_PRAVVISUTI)) return ROUTE_ISO_PRAVVISUTI;
  if (pathname.startsWith(ROUTE_PICCIRIDDI)) return ROUTE_PICCIRIDDI;
  if (pathname.startsWith(ROUTE_EXTRA)) return ROUTE_EXTRA;
  if (pathname.startsWith(ROUTE_USER_PROFILE)) return ROUTE_USER_PROFILE;
  if (pathname.startsWith(ROUTE_PROJECT)) return ROUTE_PROJECT;
};
