import React from "react";
import { Button, message, Space } from "antd";
import OneTable from "./OneTable";
import "./OnePage.css";

const OnePage = (props) => {
  const {
    dataList,
    setDataList,
    onClickDetail,
    onUpdateStory,
    setIsBusy,
    onClickReload,
    keyword,
    setKeyword,
  } = props;

  const columns = [
    {
      title: "Story ID",
      key: "id",
      render: (v, item, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Short Name",
      dataIndex: "short_name",
      key: "short_name",
    },
    {
      title: "Address",
      key: "address",
      render: (v, item) => {
        return item.address.street;
      },
    },
    {
      title: "Action",
      width: "200px",
      key: "action",
      render: (v, item, index) => {
        return (
          <Space direction={"horizontal"}>
            {index == 1 ? (
              <Button
                type={"ghost"}
                shape="round"
                size={"small"}
                onClick={() => onClickDetail(item, index)}
                style={{ color: "var(--blueColor)" }}
              >
                Detail
              </Button>
            ) : (
              <Button
                type={"primary"}
                shape="round"
                size={"small"}
                onClick={() => onUpdateStory(item, index)}
              >
                Edit
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <OneTable
        onClickReload={onClickReload}
        dataList={dataList.map((x) => ({
          ...x,
          key: x.id,
        }))}
        keyword={keyword}
        setKeyword={setKeyword}
        columns={columns}
      />
    </div>
  );
};

export default OnePage;
