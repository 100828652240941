import { googleKey } from "config/CONSTANTS";
export const GET_ALL_USERS = () => `/data/users`; ////// will remove soon
export const GET_USER_DETAILS = (id) => `/data/user`; ////// will remove soon

// export const ROOT_URL = "http://192.168.0.75/noma";
export const ASSETS_URL = "/uploads/contents/v8";
export const ROOT_URL = "https://nomapalermo.com/noma";

export const BASE_URL = ROOT_URL + "/admin/";
export const urlUserLogin = BASE_URL + "main/login"
export const urlDashboardDetail = BASE_URL + "dashboard/get_dashboard_info";
export const urlStoryList = BASE_URL + "story/get_story_list";
export const urlStoryDetailInfo = BASE_URL + "story/get_story_detail";
export const urlDeleteStory = BASE_URL + "story/delete_story";
export const urlUpdateStory = BASE_URL + "story/update_story";
export const urlAddStory = BASE_URL + "story/add_story";
export const urlPizzoList = BASE_URL + "pizzo/get_pizzo_list";
export const urlPizzoTypeList = BASE_URL + "pizzo/get_pizzo_cat_list";
export const urlUpdatePizzo = BASE_URL + "pizzo/update_pizzo";
export const urlAddPizzo = BASE_URL + "pizzo/add_pizzo";
export const urlDeletePizzo = BASE_URL + "pizzo/delete_pizzo";
export const urlUpdateExtraSound = BASE_URL + "extra/update_sound";
export const urlAddExtraSound = BASE_URL + "extra/add_sound";
export const urlUpdateStoryDetail = BASE_URL + "story/update_story_detail";
export const urlGetProjectInfo = BASE_URL + "project/get_project_detail";
export const urlUpdateProjectInfo = BASE_URL + "project/update_project_detail";
export const urlGetProfileInfo = "";
export const urlUpdateProfileInfo = "";
