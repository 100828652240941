import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import "./VideoModal.css";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ReactPlayer from "react-player";
const { TextArea } = Input;
function VideoModal(props) {
  const { show, proceed, storyItem, edit } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [oneItem, setOneItem] = useState({
    resources_base_url: "",
    augmented_sound_url: "",
  });
  const [soundUrl, setSoundUrl] = useState("");
  const [soundFile, setSoundFile] = useState("");
  useEffect(() => {
    if (storyItem != undefined) {
      setOneItem({ ...oneItem, ...storyItem });
      
      setSoundUrl(
        storyItem.resources_base_url + "/" + storyItem.augmented_sound_url
      );
    }
  }, [storyItem]);
  const onDropSound = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setSoundUrl(URL.createObjectURL(files[0]));
    setSoundFile(files[0]);
  };
  const onUpdate = async () => {
    let param = {
      id: storyItem.id,
      type: "canzoni",
      soundFile: soundFile,
    };
    proceed(param);
  };

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={"Extra Detail"}
      className="video-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={"Update"}
    >
      <Spin spinning={isBusy}>
        <Row justify="center" align="middle" gutter={32}>
          <Col>
            <ReactPlayer url={soundUrl} playing controls={true} />
          </Col>
          <Col>
            <label htmlFor="video-select-widget">
              <input
                type="file"
                id="video-select-widget"
                name="video-select-widget"
                style={{ display: "none" }}
                accept="video/mp4,video/x-m4v,video/*"
                onChange={onDropSound}
              />
              <CommonButton>Select File</CommonButton>
            </label>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(VideoModal);
