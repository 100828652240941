import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { message, Spin } from "antd";
import { apiGetDashboardDetail } from 'services/dashboardService';
import TopWidget from './TopWidget/TopWidget';
import MiddleWidget from './MiddleWidget/MiddleWidget';
import BottomWidget from './BottomWidget/BottomWidget';
import { useHistory } from 'react-router-dom';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_PAYMENT, ROUTE_STORE } from 'navigation/CONSTANTS';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { updateBalance } from 'redux/actions/userActions';

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userDataStore = useSelector(x => x.userDataStore);
    const [isBusy, setIsBusy] = useState(false);
    const [dashboardDetail, setDashboardDetail] = useState({story: 0, isop: 0, ipicciridi: 0, ilegal: 0, extra: 0, canzoni: 0 });


    useEffect(() => {
        dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_DASHBOARD }))
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetDashboardDetail()
            .then((res) => {
                setDashboardDetail({...dashboardDetail, ...res})
                setIsBusy(false);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
                setTimeout(() => {
                    history.replace(ROUTE_LOGIN);
                }, 500);
            });
    };
    const goCreatePage = (v) => {
        setTimeout(() => {
            history.push(v);
        }, 500);
    };
    return (
        <div>
            <Spin spinning={isBusy}>
                <TopWidget dashboardDetail={dashboardDetail} onClick={(v) => {
                    goCreatePage(v);
                }} />
                {/* <MiddleWidget dashboardDetail={dashboardDetail} /> */}
                {/* <BottomWidget dashboardDetail={dashboardDetail} /> */}
            </Spin>
        </div>
    );
};

export default Dashboard;