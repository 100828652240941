export const isEmpty = (list, field, errorList) => {
  if (list[field] === undefined || list[field] === "" || list[field] === " ") {
    var res = [...errorList]
    if (!errorList.includes(field)) {
      var res = [...errorList, field];
    }
    return res;
  }
  return errorList;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const ValidateEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const ValidPhone = (str) => {
  var isphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    str
  );
  return isphone;
}
export const numberWithCommas = (x) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (x == undefined || x == null || x == 0 || x == "0.00") {
    return "0.00"
  } else {
    return Number(parseFloat(x).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2
    });
  }
}