import React from 'react';
import { Row, Col } from 'antd';
import "./CommentItemWidget.css"

const CommentItemWidget = (props) => {
    const { reviewList } = props;
    return reviewList != undefined && reviewList.length > 0 && reviewList.map((commentInfo, key) => {
        return <div key={key}>
            <Row  key={key} className={'one-promotion-comment'} wrap={false} align="middle" >
                <Col flex={'60px'} className="one-promotion-comment-left">
                    <img src={
                            commentInfo.owner.image_url == null || commentInfo.owner.image_url == ""
                            ? "/assets/images/avatar_blank.png"
                            : commentInfo.owner.image_url
                        } className={commentInfo.owner.image_url == null || commentInfo.owner.image_url == ""
                        ? "one-promotion-comment-avatar0" : "one-promotion-comment-avatar"} />
                </Col>
                <Col flex={'auto'}>
                    <div className="one-promotion-comment-name">{commentInfo.owner.first_name} {commentInfo.owner.last_name}</div>
                </Col>
            </Row>
            <Row className={'one-promotion-comment'} wrap={false} align="middle" >
                <Col flex={'60px'} className="one-promotion-comment-left"/>
                <Col flex={'auto'}>
                    <div className="one-promotion-comment-comment">{commentInfo.review}</div>
                </Col>
            </Row>
        </div>
    })
};

export default CommentItemWidget;