import React, { useEffect, useState } from "react";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import { ASSETS_URL, ROOT_URL } from "services/CONSTANTS";
import { apiGetProjectInfo, apiUpdateProjectDetail, apiUpdateStoryDetail } from "services/storyService";
import ReactAudioPlayer from "react-audio-player";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import "./ProjectPage.css";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { apiGetProfileInfo } from "services/profileService";
import ReactPlayer from "react-player";
import { isEmpty } from "utils/GlobalFunctions";
const { TextArea } = Input;
const ProjectPage = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [oneItem, setOneItem] = useState({
    title_en: "",
    title_it: "",
    description_en: "",
    description_it: "",
    content_en: "",
    content_it: "",
    credit1_en: "",
    credit1_it: "",
    credit2_en: "",
    credit2_it: "",
    credit3_en: "",
    credit3_it: "",
    resources_base_url: "",
  });
  const [speakerUrl1, setSpeakerUrl1] = useState("");
  const [speakerFile1, setSpeakerFile1] = useState("");
  const [speakerUrl2, setSpeakerUrl2] = useState("");
  const [speakerFile2, setSpeakerFile2] = useState("");
  const [speakerUrl3, setSpeakerUrl3] = useState("");
  const [speakerFile3, setSpeakerFile3] = useState("");
  const [speakerUrl4, setSpeakerUrl4] = useState("");
  const [speakerFile4, setSpeakerFile4] = useState("");
  const [speakerUrl5, setSpeakerUrl5] = useState("");
  const [speakerFile5, setSpeakerFile5] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [profileFile, setProfileFile] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaFile, setMediaFile] = useState("");
  const [errorField, setErrorField] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setIsBusy(true);
    apiGetProjectInfo()
      .then((res) => {
        if (res && res.detail) {
          setOneItem({
            ...oneItem,
            title_en: res.detail.title.en,
            title_it: res.detail.title.it,
            content_en: res.detail.content.en,
            content_it: res.detail.content.it,
            description_en: res.detail.description.en,
            description_it: res.detail.description.it,
            credit1_en: res.detail.credit1.en,
            credit1_it: res.detail.credit1.it,
            credit2_en: res.detail.credit2.en,
            credit2_it: res.detail.credit2.it,
            credit3_en: res.detail.credit3.en,
            credit3_it: res.detail.credit3.it,
            resources_base_url: res.detail.resources_base_url,
          });
          setSpeakerUrl1(res.detail.speaker_icon1);
          setSpeakerUrl2(res.detail.speaker_icon2);
          setSpeakerUrl3(res.detail.speaker_icon3);
          setSpeakerUrl4(res.detail.speaker_icon4);
          setSpeakerUrl5(res.detail.speaker_icon5);
          setMediaUrl(res.detail.media);
          console.log("aa:", res.detail.media);
          setProfileUrl(res.detail.profile_icon);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(oneItem, "title_en", errorList);
    errorList = isEmpty(oneItem, "title_it", errorList);
    errorList = isEmpty(oneItem, "description_it", errorList);
    errorList = isEmpty(oneItem, "description_en", errorList);
    errorList = isEmpty(oneItem, "content_it", errorList);
    errorList = isEmpty(oneItem, "content_en", errorList);
    errorList = isEmpty(oneItem, "credit1_en", errorList);
    errorList = isEmpty(oneItem, "credit1_it", errorList);
    errorList = isEmpty(oneItem, "credit2_en", errorList);
    errorList = isEmpty(oneItem, "credit2_it", errorList);
    errorList = isEmpty(oneItem, "credit3_en", errorList);
    errorList = isEmpty(oneItem, "credit3_it", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onUpdate = () => {
    if (validateFields()) {
      if (speakerUrl1 == "") {
        message.error("Please select speaker1 icon");
        return;
      }
      if (speakerUrl2 == "") {
        message.error("Please select speaker2 icon");
        return;
      }
      if (speakerUrl3 == "") {
        message.error("Please select speaker3 icon");
        return;
      }
      if (speakerUrl4 == "") {
        message.error("Please select speaker4 icon");
        return;
      }
      if (speakerUrl5 == "") {
        message.error("Please select speaker5 icon");
        return;
      }
      if (mediaUrl == "") {
        message.error("Please select media");
        return;
      }
      if (profileUrl == "") {
        message.error("Please select profile icon");
        return;
      }
      setIsBusy(true);
      let param = {
        ...oneItem,
        speakerFile1,
        speakerFile2,
        speakerFile3,
        speakerFile4,
        speakerFile5,
        profileFile,
        mediaFile
      };
      apiUpdateProjectDetail(param)
        .then((res) => {
          setIsBusy(false);
          message.success("Detail Info has been updated successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };
  const onDropSpeakerImage = async (e, type) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    switch (type) {
      case 1:
        setSpeakerUrl1(URL.createObjectURL(files[0]));
        setSpeakerFile1(files[0]);
        break;
      case 2:
        setSpeakerUrl2(URL.createObjectURL(files[0]));
        setSpeakerFile2(files[0]);
        break;
      case 3:
        setSpeakerUrl3(URL.createObjectURL(files[0]));
        setSpeakerFile3(files[0]);
        break;
      case 4:
        setSpeakerUrl4(URL.createObjectURL(files[0]));
        setSpeakerFile4(files[0]);
        break;
      case 5:
        setSpeakerUrl5(URL.createObjectURL(files[0]));
        setSpeakerFile5(files[0]);
        break;
    }
  };
  const onDropProfileImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setProfileUrl(URL.createObjectURL(files[0]));
    setProfileFile(files[0]);
  };
  const onDropMedia = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setMediaUrl(URL.createObjectURL(files[0]));
    setMediaFile(files[0]);
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div>
          <Row gutter={[16, 8]}>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Title (en)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("title_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.title_en}
                  onChange={(e) => {
                    if (errorField.includes("title_en")) {
                      let errors = errorField.filter((x) => x != "title_en");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, title_en: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Title (it)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("title_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.title_it}
                  onChange={(e) => {
                    if (errorField.includes("title_it")) {
                      let errors = errorField.filter((x) => x != "title_it");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, title_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Description(en)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("description_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.description_en}
                  onChange={(e) => {
                    if (errorField.includes("description_en")) {
                      let errors = errorField.filter(
                        (x) => x != "description_en"
                      );
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, description_en: e.target.value });
                  }}
                />
              </div>
            </Col>

            <Col sm={24} md={12}>
              <span className="font-14 bold">Description(it)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("description_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.description_it}
                  onChange={(e) => {
                    if (errorField.includes("description_it")) {
                      let errors = errorField.filter(
                        (x) => x != "description_it"
                      );
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, description_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Content(en)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("content_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.content_en}
                  onChange={(e) => {
                    if (errorField.includes("content_en")) {
                      let errors = errorField.filter((x) => x != "content_en");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, content_en: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Content(it)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("content_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.content_it}
                  onChange={(e) => {
                    if (errorField.includes("content_it")) {
                      let errors = errorField.filter((x) => x != "content_it");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, content_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit1(en)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit1_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit1_en}
                  onChange={(e) => {
                    if (errorField.includes("credit1_en")) {
                      let errors = errorField.filter((x) => x != "credit1_en");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit1_en: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit1(it)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit1_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit1_it}
                  onChange={(e) => {
                    if (errorField.includes("credit1_it")) {
                      let errors = errorField.filter((x) => x != "credit1_it");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit1_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit2(en)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit2_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit2_en}
                  onChange={(e) => {
                    if (errorField.includes("credit2_en")) {
                      let errors = errorField.filter((x) => x != "credit2_en");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit2_en: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit2(it)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit2_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit2_it}
                  onChange={(e) => {
                    if (errorField.includes("credit2_it")) {
                      let errors = errorField.filter((x) => x != "credit2_it");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit2_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit3(en)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit3_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit3_en}
                  onChange={(e) => {
                    if (errorField.includes("credit3_en")) {
                      let errors = errorField.filter((x) => x != "credit3_en");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit3_en: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Credit3(it)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  className={
                    errorField.includes("credit3_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.credit3_it}
                  onChange={(e) => {
                    if (errorField.includes("credit3_it")) {
                      let errors = errorField.filter((x) => x != "credit3_it");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, credit3_it: e.target.value });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 8]} style={{ marginTop: 20 }}>
            <Col sm={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker1 Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget">
                    <input
                      type="file"
                      id="speaker-image-widget"
                      name="speaker-image-widget"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={(e) => {
                        onDropSpeakerImage(e, 1);
                      }}
                    />
                    <Avatar
                      src={speakerUrl1}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker2 Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget2">
                    <input
                      type="file"
                      id="speaker-image-widget2"
                      name="speaker-image-widget2"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={(e) => {
                        onDropSpeakerImage(e, 2);
                      }}
                    />
                    <Avatar
                      src={speakerUrl2}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker3 Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget3">
                    <input
                      type="file"
                      id="speaker-image-widget3"
                      name="speaker-image-widget3"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={(e) => {
                        onDropSpeakerImage(e, 3);
                      }}
                    />
                    <Avatar
                      src={speakerUrl3}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker4 Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget4">
                    <input
                      type="file"
                      id="speaker-image-widget4"
                      name="speaker-image-widget4"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={(e) => {
                        onDropSpeakerImage(e, 4);
                      }}
                    />
                    <Avatar
                      src={speakerUrl4}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker5 Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget5">
                    <input
                      type="file"
                      id="speaker-image-widget5"
                      name="speaker-image-widget5"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={(e) => {
                        onDropSpeakerImage(e, 5);
                      }}
                    />
                    <Avatar
                      src={speakerUrl5}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <span className="font-14 bold">Profile Icon</span>
              <div className="story-avatar-rect">
                <label htmlFor="profile-image-widget">
                  <input
                    type="file"
                    id="profile-image-widget"
                    name="profile-image-widget"
                    style={{ display: "none" }}
                    accept=".jpg"
                    onChange={onDropProfileImage}
                  />
                  <Avatar
                    src={profileUrl}
                    size={120}
                    style={{ marginLeft: 40 }}
                  />
                  <span className="story-avatar-edit-item">
                    <EditFilled
                      style={{ color: "var(--blueColor)", fontSize: 24 }}
                    />
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }} gutter={32}>
            <Col>
              <span className="font-14 bold">Media</span>
            </Col>
            <Col>
              <label htmlFor="video-select-widget">
                <input
                  type="file"
                  id="video-select-widget"
                  name="video-select-widget"
                  style={{ display: "none" }}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={onDropMedia}
                />
                <CommonButton>Select File</CommonButton>
              </label>
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            gutter={32}
            style={{ marginTop: 20 }}
          >
            <Col>
              <ReactPlayer url={mediaUrl} playing controls={true} />
            </Col>
          </Row>
          <Row style={{ marginTop: 30, marginBottom: 20 }} justify="center">
            <CommonButton style={{ padding: "8px 32px" }} onClick={()=>{
                onUpdate()
            }}>Update</CommonButton>
          </Row>
        </div>
      </Spin>
    </CommonDivWidget>
  );
};

export default ProjectPage;
