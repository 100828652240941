import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import "./DetailModal.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import LogoutIcon from "../../../assets/icons/ic_logout.svg";
const { TextArea } = Input;
function DetailModal(props) {
  const { show, proceed, storyItem, edit } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [content, setContent] = useState("");
  const [storyDescriptionEn, setStoryDescriptionEn] = useState("");
  const [storyDescriptionIt, setStoryDescriptionIt] = useState("");
  const [speakerUrl, setSpeakerUrl] = useState("");
  const [speakerFile, setSpeakerFile] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [profileFile, setProfileFile] = useState("");
  const [errorField, setErrorField] = useState([]);
  const [name, setName] = useState("");
  const [short_name, setShortName] = useState("");
  useEffect(() => {
    if (storyItem != undefined) {
      setStoryDescriptionEn(storyItem.description.en);
      setStoryDescriptionIt(storyItem.description.it);
      setSpeakerUrl(storyItem.speaker_icon);
      setProfileUrl(storyItem.profile_icon);
      setName(storyItem.name);
      setShortName(storyItem.short_name);
    }
  }, [storyItem]);

  const onUpdate = async () => {
    if (name == "") {
      message.error("Please input name");
      return;
    }
    if (short_name == "") {
      message.error("Please input short name");
      return;
    }
    if (storyDescriptionEn == "") {
      message.error("Please input Description in english");
      return;
    }
    if (storyDescriptionIt == "") {
      message.error("Please input Description in italian");
      return;
    }
    let param = {
      id: storyItem?.id,
      type: "ipicciridi",
      description_en: storyDescriptionEn,
      description_it: storyDescriptionIt,
      name: name,
      short_name: short_name,
      speaker: speakerFile,
      profile: profileFile,
    };
    proceed(param);
  };
  const onEditorStateChange = (e) => {
    if (e != null) {
      let selStr = draftToHtml(convertToRaw(e.getCurrentContent()));
      setContent(selStr);
      setEditorState(e);
    }
  };
  const onDropSpeakerImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setSpeakerUrl(URL.createObjectURL(files[0]));
    setSpeakerFile(files[0]);
  };
  const onDropProfileImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setProfileUrl(URL.createObjectURL(files[0]));
    setProfileFile(files[0]);
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={"Detail"}
      className="story-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={"Update"}
    >
      <Spin spinning={isBusy}>
        <div>
          <Row gutter={32} justify="center">
            <Col xs={24} xl={16}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="pic-speaker-image-widget">
                    <input
                      type="file"
                      id="pic-speaker-image-widget"
                      name="pic-speaker-image-widget"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={onDropSpeakerImage}
                    />
                    <Avatar
                      src={speakerUrl}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col sm={24} md={12}>
                    <span className="font-14 bold">Name</span>
                    <div className="content-section-title">
                      <Input
                        className={
                          errorField.includes("name")
                            ? `common-input-error`
                            : `common-input`
                        }
                        value={name}
                        onChange={(e) => {
                          if (errorField.includes("name")) {
                            let errors = errorField.filter((x) => x != "name");
                            setErrorField([...errors]);
                          }
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={24} md={12}>
                    <span className="font-14 bold">Short Name</span>
                    <div className="content-section-title">
                      <Input
                        className={
                          errorField.includes("short_name")
                            ? `common-input-error`
                            : `common-input`
                        }
                        value={short_name}
                        onChange={(e) => {
                          if (errorField.includes("short_name")) {
                            let errors = errorField.filter(
                              (x) => x != "short_name"
                            );
                            setErrorField([...errors]);
                          }
                          setShortName(e.target.value)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{marginTop: 10}}>
                <span className="font-14 bold">{`Description(en)`}</span>
                <div className="story-description-rect">
                  <TextArea
                    rows={15}
                    value={storyDescriptionEn}
                    onChange={(e) => {
                      setStoryDescriptionEn(e.target.value);
                    }}
                  />
                </div>
                <div className="font-14 bold mt-10">{`Description(it)`}</div>
                <div className="story-description-rect">
                  <TextArea
                    rows={15}
                    value={storyDescriptionIt}
                    onChange={(e) => {
                      setStoryDescriptionIt(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={8}>
              <span className="font-14 bold">Profile Icon</span>
              <div className="mt-10">
                <div className="story-avatar-rect">
                  <label htmlFor="pic-profile-image-widget">
                    <input
                      type="file"
                      id="pic-profile-image-widget"
                      name="pic-profile-image-widget"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={onDropProfileImage}
                    />
                    <img
                      src={profileUrl == "" ? LogoutIcon : profileUrl}
                      className="story-profile-img"
                      style={
                        profileUrl == "" ? { width: 100, opacity: 0.4 } : {}
                      }
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-20">
                <span className="font-14 bold">THE GUARDS</span>
                {storyItem != undefined &&
                  storyItem.bodyguard_team != undefined &&
                  storyItem.bodyguard_team.map((x, index) => {
                    return (
                      <div key={index} className="mt-10">
                        <Avatar src={x.bodyguard_icon} size={40} />
                        <span className="ml-10">{x.bodyguard_name}</span>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(DetailModal);
