import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import "./VideoModal.css";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ReactPlayer from "react-player";
const { TextArea } = Input;
function VideoModal(props) {
  const { show, proceed, storyItem, edit } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [errorField, setErrorField] = useState([]);
  const [oneItem, setOneItem] = useState({
    resources_base_url: "",
    augmented_sound_url: "",
    name: "",
    short_name: "",
  });
  const [soundUrl, setSoundUrl] = useState("");
  const [soundFile, setSoundFile] = useState("");
  useEffect(() => {
    if (storyItem != undefined) {
      setOneItem({ ...oneItem, ...storyItem });

      setSoundUrl(
        storyItem.resources_base_url + "/" + storyItem.augmented_sound_url
      );
    }
  }, [storyItem]);
  const onDropSound = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setSoundUrl(URL.createObjectURL(files[0]));
    setSoundFile(files[0]);
  };
  const onUpdate = async () => {
    if (oneItem.name == "") {
      message.error("Please input name");
      return;
    }
    if (oneItem.short_name == "") {
      message.error("Please input short name");
      return;
    }
    if (soundUrl == "") {
      message.error("Please upload media");
      return;
    }
    let param = {
      id: storyItem?.id,
      type: "canzoni",
      soundFile: soundFile,
      name: oneItem.name,
      short_name: oneItem.short_name,
    };
    console.log("p:", param);
    proceed(param);
  };

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={"Canzoni Detail"}
      className="video-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={storyItem == undefined ? "Add" : "Update"}
    >
      <Spin spinning={isBusy}>
        <Row gutter={[16, 8]}>
          <Col sm={24} md={12}>
            <span className="font-14 bold">Name</span>
            <div className="content-section-title">
              <Input
                className={
                  errorField.includes("name")
                    ? `common-input-error`
                    : `common-input`
                }
                value={oneItem.name}
                onChange={(e) => {
                  if (errorField.includes("name")) {
                    let errors = errorField.filter((x) => x != "name");
                    setErrorField([...errors]);
                  }
                  setOneItem({ ...oneItem, name: e.target.value });
                }}
              />
            </div>
          </Col>
          <Col sm={24} md={12}>
            <span className="font-14 bold">Description</span>
            <div className="content-section-title">
              <Input
                className={
                  errorField.includes("short_name")
                    ? `common-input-error`
                    : `common-input`
                }
                value={oneItem.short_name}
                onChange={(e) => {
                  if (errorField.includes("short_name")) {
                    let errors = errorField.filter((x) => x != "short_name");
                    setErrorField([...errors]);
                  }
                  setOneItem({ ...oneItem, short_name: e.target.value });
                }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: 20}} gutter={32}>
          <Col><span className="font-14 bold">Media</span></Col>
          <Col>
            <label htmlFor="video-select-widget">
              <input
                type="file"
                id="video-select-widget"
                name="video-select-widget"
                style={{ display: "none" }}
                accept="video/mp4,video/x-m4v,video/*"
                onChange={onDropSound}
              />
              <CommonButton>Select File</CommonButton>
            </label>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          gutter={32}
          style={{ marginTop: 20 }}
        >
          <Col>
            <ReactPlayer url={soundUrl} playing controls={true} />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(VideoModal);
