import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetProfileInfo, urlUpdateProfileInfo } from "./CONSTANTS";
export const apiGetProfileInfo = () => {
    const url = urlGetProfileInfo;
    return new Promise((resolve, reject) => {
      axiosGet(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};
export function apiUpdateProfile(userData, new_image_url) {
    const url = urlUpdateProfileInfo;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("first_name", userData.first_name);
    formData.append("last_name", userData.last_name);
    formData.append("email", userData.email);
    formData.append("countryCode", userData.countryCode);
    formData.append("phoneNumber", userData.phoneNumber);
    formData.append("old_password", userData.old_password);
    formData.append("new_password", userData.new_password);
    if (new_image_url != undefined) {
      if (new_image_url instanceof File) {
        formData.append("new_image_url", new_image_url);
      }
    }
  
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
    
}