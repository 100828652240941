import React from "react";
import { useState } from "react";
import { Row, Col, Tabs, Spin, message } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./ExtraPage.css";
import OnePage from "./OnePage/OnePage";
import {
  apiGetStoryDetailInfo,
  apiGetStoryList,
  apiUpdateExtraSound,
  apiUpdateStory,
} from "services/storyService";
import { useEffect } from "react";
import { showDetailModal } from "./DetailModal/showDetailModal";
import { showSoundModal } from "./SoundModal/showSoundModal";
import { useHistory } from "react-router-dom";
import { ROUTE_CANZONI } from "navigation/CONSTANTS";
const { TabPane } = Tabs;
function ExtraPage(props) {
  const history = useHistory();
  const [activePage, setActivePage] = useState("5");
  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    getStoryList();
  }, [activePage]);
  const getStoryList = () => {
    setIsBusy(true);
    apiGetStoryList(activePage)
      .then((res) => {
        setDataList(res.list);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onUpdateStory = async (item, index) => {
    if (index == 0) {
      setIsBusy(true);
      apiGetStoryDetailInfo("extra_third")
        .then((res) => {
          setIsBusy(false);
          showModal(res);
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else if (index == 1) {
    } else if (index == 2) {
      let dlgRes = await showSoundModal({
        storyItem: item,
        edit: true,
      });
      if (dlgRes == null) return;
      setIsBusy(true);
      apiUpdateExtraSound(dlgRes)
        .then((res) => {
          setIsBusy(false);
          getStoryList();
          message.success("It has been updated successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };
  const showModal = async (item) => {
    let dlgRes = await showDetailModal({
      contentInfo: item,
      edit: true,
    });
    if (dlgRes == null) return;
  };
  const onClickDetail = async (item, index) => {
    if (index == 0) {
      let dlgRes = await showSoundModal({
        storyItem: item,
        edit: false,
      });
      if (dlgRes == null) return;
    } else if (index == 1) {
      history.push(ROUTE_CANZONI);
    } else if (index == 2) {
    }
  };

  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <OnePage
          activePage={activePage}
          dataList={dataList}
          setDataList={setDataList}
          onClickDetail={onClickDetail}
          onUpdateStory={onUpdateStory}
          setIsBusy={setIsBusy}
          onClickReload={getStoryList}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      </Spin>
    </CommonDivWidget>
  );
}

export default ExtraPage;
