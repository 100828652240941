import React from "react";
import "./AuthDialogWidget.css";
import LogoIcon from "../../assets/images/logo.png"
function AuthDialogWidget(props) {
  return (
    <div className="auth-dialog-widget">
        <img src={LogoIcon} style={{ width: "250px", height: "auto", paddingTop: props.top, marginBottom: 40 }} />
        <div className={"title"}>
          {props.title}
          <span className="sub_title">{props.sub_title !== undefined && <div>{props.sub_title}</div>}</span>
        </div>
        {props.children}
      </div>
  );
}

export default AuthDialogWidget;
