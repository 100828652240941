import { axiosGet, axiosPost, axiosPostMultiPart } from "./ajaxServices";
import { urlAddPizzo, urlDeletePizzo, urlPizzoList, urlPizzoTypeList, urlUpdatePizzo } from "./CONSTANTS";

export const apiGetPizzoList = () => {
  const url = urlPizzoList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetPizzoCatList = () => {
  const url = urlPizzoTypeList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function apiUpdatePizzo(storyInfo) {
  const url = urlUpdatePizzo;
  var formData = new FormData();
  formData.append("id", storyInfo.id);
  formData.append("name", storyInfo.name);
  formData.append("description", storyInfo.description);
  formData.append("cat_en", storyInfo.cat_en);
  formData.append("cat_it", storyInfo.cat_it);
  formData.append("subcat_en", storyInfo.subcat_en);
  formData.append("subcat_it", storyInfo.subcat_it);
  formData.append("phone", storyInfo.phone);
  formData.append("web", storyInfo.web);
  formData.append("cat", storyInfo.cat);
  formData.append("cat_type", storyInfo.cat_type);
  formData.append("subcat", storyInfo.subcat);
  formData.append("lat", storyInfo.lat);
  formData.append("lng", storyInfo.lng);
  formData.append("city", storyInfo.city);
  formData.append("street", storyInfo.street);
  formData.append("country", storyInfo.country);

 
  return new Promise((resolve, reject) => {
    axiosPost(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiAddPizzo(storyInfo) {
  const url = urlAddPizzo;
  var formData = new FormData();
  formData.append("name", storyInfo.name);
  formData.append("description", storyInfo.description);
  formData.append("cat_en", storyInfo.cat_en);
  formData.append("cat_it", storyInfo.cat_it);
  formData.append("subcat_en", storyInfo.subcat_en);
  formData.append("subcat_it", storyInfo.subcat_it);
  formData.append("phone", storyInfo.phone);
  formData.append("web", storyInfo.web);
  formData.append("cat", storyInfo.cat);
  formData.append("cat_type", storyInfo.cat_type);
  formData.append("subcat", storyInfo.subcat);
  formData.append("lat", storyInfo.lat);
  formData.append("lng", storyInfo.lng);
  formData.append("city", storyInfo.city);
  formData.append("street", storyInfo.street);
  formData.append("country", storyInfo.country);
 
  return new Promise((resolve, reject) => {
    axiosPost(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function apiDeletePizzo(id) {
  const url = urlDeletePizzo;
  var formData = new FormData();
  formData.append("id", id);
 
  return new Promise((resolve, reject) => {
    axiosPost(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

