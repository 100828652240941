import React from "react";
import { useState } from "react";
import { Row, Col, Tabs, Spin, message } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./IsoPage.css";
import OnePage from "./OnePage/OnePage";
import {
  apiAddStory,
  apiDeleteStory,
  apiGetStoryList,
  apiUpdateStory,
} from "services/storyService";
import { useEffect } from "react";
import { showDetailModal } from "./DetailModal/showDetailModal";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ContentSection from "./ContentSection/ContentSection";
const { TabPane } = Tabs;
function IsoPage(props) {
  const [activePage, setActivePage] = useState("2");
  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [contentInfo, setContentInfo] = useState(null);

  useEffect(() => {
    getStoryList();
  }, [activePage]);
  const getStoryList = () => {
    setIsBusy(true);
    apiGetStoryList(activePage)
      .then((res) => {
        setDataList(res.list);
        setContentInfo(res.detail);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onUpdateStory = async (item) => {
    let dlgRes = await showDetailModal({
      storyItem: item,
      edit: true,
    });
    if (dlgRes == null) return;
    setIsBusy(true);
    apiUpdateStory(dlgRes)
      .then((res) => {
        setIsBusy(false);
        getStoryList();
        message.success("Updated successfully.");
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onClickDelete = async (item) => {
    setIsBusy(true);
    apiDeleteStory(item.id)
      .then((res) => {
        setIsBusy(false);
        let tmpList = [...dataList];
        setDataList(tmpList.filter((x) => x.id != item.id));
        message.success("Deleted successfully.");
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onAdd = async () => {
    let dlgRes = await showDetailModal({
      storyItem: undefined,
      edit: true,
    });
    if (dlgRes == null) return;
    setIsBusy(true);
    apiAddStory(dlgRes)
      .then((res) => {
        setIsBusy(false);
        getStoryList();
        message.success("Added successfully.");
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Tabs type="card">
          <TabPane tab="Detail Info" key="item-1">
            <ContentSection setIsBusy={setIsBusy} contentInfo={contentInfo} />
          </TabPane>
          <TabPane tab="Item Info" key="item-2">
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col>
                <CommonButton
                  onClick={() => {
                    onAdd();
                  }}
                >
                  Add
                </CommonButton>
              </Col>
              {/* <Col>
                <Search
                  placeholder={"Search name"}
                  allowClear
                  onSearch={(v) => {
                    onSearch(v);
                  }}
                  enterButton
                />
              </Col> */}
            </Row>
            <OnePage
              activePage={activePage}
              dataList={dataList}
              setDataList={setDataList}
              onClickDelete={onClickDelete}
              onUpdateStory={onUpdateStory}
              setIsBusy={setIsBusy}
              onClickReload={getStoryList}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </CommonDivWidget>
  );
}

export default IsoPage;
