// @flow strict

import * as React from "react";
import { Select } from "antd";
import "./ProductTypeSelector.css";
const { Option } = Select;

function ProductTypeSelector(props) {
  const { field, productInfo, setProductInfo, isCategory } = props;
  const { displayField, idField } = props;
  const { errorField, setErrorField } = props;
  const { placeholder, list } = props;
  const display = displayField === undefined ? "name" : displayField;
  const id = idField === undefined ? "id" : idField;
  return (
    <div>
      <Select
        showSearch
        disabled={list == undefined || list.length == 0}
        style={{
          width: "100%",
          border: errorField.includes(field) ? "1px solid red" : "0px",
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        value={
          productInfo[field]
        }
        onChange={(value, x, y) => {
          console.log(value)
          var info = productInfo;
          info[field] = value;
          if (errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            setErrorField([...errors]);
          }
          setProductInfo({ ...info });
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {list != undefined &&
          list.map((info) => {
            return (
              <Option
                key={`${field}_key${info.id}`}
                value={info.id}
                disabled={isCategory === true && info.parent == true}
                style={{
                  fontWeight:
                    isCategory == true && info.parent == true
                      ? "bold"
                      : "normal",
                  color: "var(--blackColor)",
                }}
              >
                {isCategory == true && !info.parent
                  ? "---" + info[display]
                  : info[display]}
              </Option>
            );
          })}
      </Select>
    </div>
  );
}

export default ProductTypeSelector;
