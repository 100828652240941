import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import "./DetailModal.css";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import { ASSETS_URL, ROOT_URL } from "services/CONSTANTS";
import { apiUpdateStoryDetail } from "services/storyService";
import ReactAudioPlayer from "react-audio-player";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
const { TextArea } = Input;
function DetailModal(props) {
  const { show, proceed, contentInfo, edit } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [oneItem, setOneItem] = useState({
    name: "",
    short_name: "",
    description_en: "",
    description_it: "",
    bodyguard_title_en: "",
    bodyguard_title_it: "",
  });
  const [speakerUrl, setSpeakerUrl] = useState("");
  const [speakerFile, setSpeakerFile] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [profileFile, setProfileFile] = useState("");
  const [soundUrl, setSoundUrl] = useState("");
  const [soundFile, setSoundFile] = useState("");
  const [errorField, setErrorField] = useState([]);
  const onDropSpeakerImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setSpeakerUrl(URL.createObjectURL(files[0]));
    setSpeakerFile(files[0]);
  };
  const onDropProfileImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setProfileUrl(URL.createObjectURL(files[0]));
    setProfileFile(files[0]);
  };
  const onDropSound = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setSoundUrl(URL.createObjectURL(files[0]));
    setSoundFile(files[0]);
  };
  useEffect(() => {
    if (contentInfo != undefined && contentInfo != null) {
      setOneItem({
        ...oneItem,
        id: contentInfo.id,
        name: contentInfo.name,
        short_name: contentInfo.short_name,
        description_en: contentInfo.description.en,
        description_it: contentInfo.description.it,
        bodyguard_title_en: contentInfo.bodyguard_title.en,
        bodyguard_title_it: contentInfo.bodyguard_title.it,
      });
      if (contentInfo.augmented_sound_url) {
        setSoundUrl(
          ROOT_URL +
            ASSETS_URL +
            contentInfo.resources_base_url +
            contentInfo.augmented_sound_url
        );
      }
      if (contentInfo.profile_icon) {
        setProfileUrl(
          ROOT_URL +
            ASSETS_URL +
            contentInfo.resources_base_url +
            contentInfo.profile_icon
        );
      }
      if (contentInfo.speaker_icon) {
        setSpeakerUrl(
          ROOT_URL +
            ASSETS_URL +
            contentInfo.resources_base_url +
            contentInfo.speaker_icon
        );
      }
    }
  }, [contentInfo]);

  const onUpdate = () => {
    setIsBusy(true);
    let param = {
      ...oneItem,
      speaker: speakerFile,
      profile: profileFile,
      soundFile,
      type: "extra_third",
    };
    setIsBusy(true);
    apiUpdateStoryDetail(param)
      .then((res) => {
        setIsBusy(false);
        message.success("Detail Info has been updated successfully")
        proceed(true)
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={"Story Detail"}
      className="story-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={"Update"}
    >
      <Spin spinning={isBusy}>
        <div>
          <Row gutter={[16, 8]}>
            <Col sm={24} md={12}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker Icon</span>
                <div className="story-avatar-rect">
                  <label htmlFor="speaker-image-widget">
                    <input
                      type="file"
                      id="speaker-image-widget"
                      name="speaker-image-widget"
                      style={{ display: "none" }}
                      accept=".jpg"
                      onChange={onDropSpeakerImage}
                    />
                    <Avatar
                      src={speakerUrl}
                      size={120}
                      style={{ marginLeft: 20 }}
                    />
                    <span className="story-avatar-edit-item">
                      <EditFilled
                        style={{ color: "var(--blueColor)", fontSize: 24 }}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Profile Icon</span>
              <div className="story-avatar-rect">
                <label htmlFor="profile-image-widget">
                  <input
                    type="file"
                    id="profile-image-widget"
                    name="profile-image-widget"
                    style={{ display: "none" }}
                    accept=".jpg"
                    onChange={onDropProfileImage}
                  />
                  <Avatar
                    src={profileUrl}
                    size={120}
                    style={{ marginLeft: 20 }}
                  />
                  <span className="story-avatar-edit-item">
                    <EditFilled
                      style={{ color: "var(--blueColor)", fontSize: 24 }}
                    />
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            gutter={32}
            style={{ marginTop: 20 }}
          >
            <Col>
              {/* <audio controls>
              <source src={soundUrl} type="audio/mpeg" />
            </audio> */}
              <ReactAudioPlayer src={soundUrl} autoPlay controls />
            </Col>
            <Col>
              <label htmlFor="sound-sel-widget">
                <input
                  type="file"
                  id="sound-sel-widget"
                  name="sound-sel-widget"
                  style={{ display: "none" }}
                  accept=".mp3,audio/*"
                  onChange={onDropSound}
                />
                <CommonButton>Select File</CommonButton>
              </label>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Name</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.name}
                  onChange={(e) => {
                    if (errorField.includes("name")) {
                      let errors = errorField.filter((x) => x != "name");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, name: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Short Name</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("short_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.short_name}
                  onChange={(e) => {
                    if (errorField.includes("short_name")) {
                      let errors = errorField.filter((x) => x != "short_name");
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, short_name: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Description(en)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("description_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.description_en}
                  onChange={(e) => {
                    if (errorField.includes("description_en")) {
                      let errors = errorField.filter(
                        (x) => x != "description_en"
                      );
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, description_en: e.target.value });
                  }}
                />
              </div>
            </Col>

            <Col sm={24} md={12}>
              <span className="font-14 bold">Description(it)</span>
              <div className="content-section-title">
                <Input
                  className={
                    errorField.includes("description_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={oneItem.description_it}
                  onChange={(e) => {
                    if (errorField.includes("description_it")) {
                      let errors = errorField.filter(
                        (x) => x != "description_it"
                      );
                      setErrorField([...errors]);
                    }
                    setOneItem({ ...oneItem, description_it: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Content(en)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  value={oneItem.bodyguard_title_en}
                  onChange={(e) => {
                    setOneItem({
                      ...oneItem,
                      bodyguard_title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <span className="font-14 bold">Content(it)</span>
              <div className="content-section-title">
                <TextArea
                  rows={10}
                  value={oneItem.bodyguard_title_it}
                  onChange={(e) => {
                    setOneItem({
                      ...oneItem,
                      bodyguard_title_it: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(DetailModal);
