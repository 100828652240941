// @flow strict

import { StarFilled } from "@ant-design/icons";
import React from "react";

const StarWidget = (props) => {
  const { updateRating, rating, margin, size, value } = props;
  return (
    <StarFilled
      style={{
        color: rating >= value ? "var(--yellowColor)" : "var(--greyColor)",
        marginRight: margin === undefined ? 2 : margin,
        fontSize: size === undefined ? 16 : size,
      }}
      className={`${updateRating === undefined ? "" : "custom-button"}`}
      onClick={() => {
        if (updateRating === undefined) return;
        updateRating(value);
      }}
    />
  );
};
function RatingWidget(props) {
  const { rating, margin, size } = props;
  const { updateRating } = props;
  return (
    <div>
      <StarWidget
        rating={rating}
        margin={margin}
        size={size}
        updateRating={updateRating}
        value={1}
      />
      <StarWidget
        rating={rating}
        margin={margin}
        size={size}
        updateRating={updateRating}
        value={2}
      />
      <StarWidget
        rating={rating}
        margin={margin}
        size={size}
        updateRating={updateRating}
        value={3}
      />
      <StarWidget
        rating={rating}
        margin={margin}
        size={size}
        updateRating={updateRating}
        value={4}
      />
      <StarWidget
        rating={rating}
        margin={margin}
        size={size}
        updateRating={updateRating}
        value={5}
      />
    </div>
  );
}

export default RatingWidget;
