import React from "react";
import { useState } from "react";
import { Row, Col, Tabs, Spin, message } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./StoryPage.css";
import OnePage from "./OnePage/OnePage";
import {
  apiGetStoryList,
  apiUpdateExtraSound,
  apiUpdateStory,
} from "services/storyService";
import { useEffect } from "react";
import { showDetailModal } from "./DetailModal/showDetailModal";
import ContentSection from "./ContentSection/ContentSection";
import { showVideoModal } from "./VideoModal/showVideoModal";
const { TabPane } = Tabs;
function StoryPage(props) {
  const [activePage, setActivePage] = useState("1");
  const [dataList, setDataList] = useState([]);
  const [contentInfo, setContentInfo] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    getStoryList();
  }, [activePage]);
  const getStoryList = () => {
    setIsBusy(true);
    apiGetStoryList(activePage)
      .then((res) => {
        setDataList(res.list);
        setContentInfo(res.detail);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onUpdateStory = async (item) => {
    let dlgRes = await showDetailModal({
      storyItem: item,
      edit: true,
    });
    if (dlgRes == null) return;
    setIsBusy(true);
    apiUpdateStory(dlgRes)
      .then((res) => {
        setIsBusy(false);
        getStoryList();
        message.success("Story has been updated successfully.");
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onClickDetail = async (item) => {
    // let dlgRes = await showDetailModal({
    //   storyItem: item,
    //   edit: false,
    // });
    // if (dlgRes == null) return;
  };

  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        {/* <Tabs
        type="card"
        >
          <TabPane tab="Detail Info" key="item-1">
            <ContentSection setIsBusy={setIsBusy} contentInfo={contentInfo} />
          </TabPane>
          <TabPane tab="Item Info" key="item-2">
            <OnePage
              activePage={activePage}
              dataList={dataList}
              setDataList={setDataList}
              onClickDetail={onClickDetail}
              onUpdateStory={onUpdateStory}
              setIsBusy={setIsBusy}
              onClickReload={getStoryList}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </TabPane>
        </Tabs> */}
        <OnePage
          activePage={activePage}
          dataList={dataList}
          setDataList={setDataList}
          onClickDetail={onClickDetail}
          onUpdateStory={onUpdateStory}
          setIsBusy={setIsBusy}
          onClickReload={getStoryList}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      </Spin>
    </CommonDivWidget>
  );
}

export default StoryPage;
