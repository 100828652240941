import { message } from "antd";
import { useAuth } from "navigation/Auth/ProvideAuth";
import CodeVerificationWidget from "components/CodeVerificationWidget/CodeVerificationWidget";

import { REGISTER_WITH } from "config/CONSTANTS";
import {
  ROUTE_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { UpdateCurrentRoot, UpdateQrCodeList } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import InputPage from "./InputPage";

const LOGIN_PAGES = {
  InputPage: "InputPage",
  ActivationCodePage: "ActivationCodePage",
  InputLocationPage: "InputLocationPage",
};
const pageList = [
  LOGIN_PAGES.InputPage,
  LOGIN_PAGES.ActivationCodePage,
  LOGIN_PAGES.InputLocationPage,
];
function LoginPage(props) {
  const appDataStore = useSelector(x => x.appDataStore);
  const dispatch = useDispatch();
  const auth = useAuth();
  const [currentPage, setCurrentpage] = useState(0);
  const [userDetail, setUserDetail] = useState({
    register_with: REGISTER_WITH.email
  });
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    dispatch(updateUser({ token: undefined }));
  }, []);

  const onClickLogin = async () => {
    auth
      .signin(userDetail)
      .then((res) => {
        const { token } = res;
        onSuccessLogin(res);
    
      })
      .catch((err) => {
        message.error(err);
      });

  };
  const onSuccessLogin = (info) => {
    if (info.user_info === undefined || info.token === undefined) {
      return;
    }
    dispatch(updateUser(info));


    const { from } = { from: { pathname: appDataStore.currentRoute === undefined ? ROUTE_DASHBOARD : appDataStore.currentRoute } };

    setTimeout(() => {
      props.history.replace(from.pathname == "/admin/login"?{pathname:ROUTE_DASHBOARD}:from);
    }, 500);
  };
  const onClickRegister = () => {
    // props.history.push(ROUTE_REGISTER);
  };

  const onClickRegisterVendor = () => {
    
  };
  return (
    <div style={{ color: 'var(--blackColor)' }}>
      <InputPage
        onClickLogin={onClickLogin}
        onClickRegister={onClickRegister}
        onClickRegisterVendor={onClickRegisterVendor}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        isBusy={isBusy}
      />
    </div>
  );
}

export default withRouter(LoginPage);
