import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import parse from 'html-react-parser';
import "./DetailModal.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Avatar, Col, Input, message, Row, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
const { TextArea } = Input;
function DetailModal(props) {
  const { show, proceed, storyItem, edit } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [content, setContent] = useState("");
  const [storyDescriptionEn, setStoryDescriptionEn] = useState("");
  const [storyDescriptionIt, setStoryDescriptionIt] = useState("");
  const [speakerUrl, setSpeakerUrl] = useState("");
  const [speakerFile, setSpeakerFile] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [profileFile, setProfileFile] = useState("");
  useEffect(() => {
    if (storyItem != undefined) {
      setStoryDescriptionEn(storyItem.description.en);
      setStoryDescriptionIt(storyItem.description.it);
      setSpeakerUrl(storyItem.speaker_icon);
      setProfileUrl(storyItem.profile_icon);
    }
  }, [storyItem]);
  
  const onUpdate = async () => {
    if (storyDescriptionEn == "") {
      message.error("Please input Description in english")
      return
    }
    if (storyDescriptionIt == "") {
      message.error("Please input Description in italian")
      return
    }
    let param = {
      id: storyItem.id,
      type: "story",
      en: storyDescriptionEn,
      it: storyDescriptionIt,
      speaker: speakerFile,
      profile: profileFile
    }
    proceed(param)
  };
  const onEditorStateChange = (e) => {
    if (e != null) {
      let selStr = draftToHtml(convertToRaw(e.getCurrentContent()));
      setContent(selStr);
      setEditorState(e);
    }
  };
  const onDropSpeakerImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setSpeakerUrl(URL.createObjectURL(files[0]));
    setSpeakerFile(files[0]);
  };
  const onDropProfileImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setProfileUrl(URL.createObjectURL(files[0]));
    setProfileFile(files[0]);
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={"Story Detail"}
      className="story-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={"Update"}
    >
      <Spin spinning={isBusy}>
        <div>
          <Row gutter={32} justify="center">
            <Col xs={24} xl={16}>
              <div style={{ display: "inline-block" }}>
                <span className="font-14 bold">Speaker Icon</span>
                {edit ? (
                  <div className="story-avatar-rect">
                    <label htmlFor="speaker-image-widget">
                      <input
                        type="file"
                        id="speaker-image-widget"
                        name="speaker-image-widget"
                        style={{ display: "none" }}
                        accept=".jpg"
                        onChange={onDropSpeakerImage}
                      />
                      <Avatar
                        src={speakerUrl}
                        size={120}
                        style={{ marginLeft: 20 }}
                      />
                      <span className="story-avatar-edit-item">
                        <EditFilled
                          style={{ color: "var(--whiteColor)", fontSize: 24 }}
                        />
                      </span>
                    </label>
                  </div>
                ) : (
                  <Avatar
                    src={storyItem.speaker_icon}
                    size={120}
                    style={{ marginLeft: 20 }}
                  />
                )}
              </div>
              {edit ? (
                <div>
                  <span className="font-14 bold">{`Description(en)`}</span>
                  <div className="story-description-rect">
                    <TextArea
                      rows={15}
                      value={storyDescriptionEn}
                      onChange={(e) => {
                        setStoryDescriptionEn(e.target.value);
                      }}
                    />
                  </div>
                  <div className="font-14 bold mt-10">{`Description(it)`}</div>
                  <div className="story-description-rect">
                    <TextArea
                      rows={15}
                      value={storyDescriptionIt}
                      onChange={(e) => {
                        setStoryDescriptionIt(e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <span className="font-14 bold">{`Description(en)`}</span>
                  <div className="story-description-rect">
                    {parse(storyItem.description.en)}
                  </div>
                  <span className="font-14 bold mt-10">{`Description(it)`}</span>
                  <div className="story-description-rect">
                    {parse(storyItem.description.it)}
                  </div>
                </div>
              )}
            </Col>
            <Col xs={24} xl={8}>
              <span className="font-14 bold">Profile Icon</span>
              <div className="mt-10">
                {edit ? (
                  <div className="story-avatar-rect">
                    <label htmlFor="profile-image-widget">
                      <input
                        type="file"
                        id="profile-image-widget"
                        name="profile-image-widget"
                        style={{ display: "none" }}
                        accept=".jpg"
                        onChange={onDropProfileImage}
                      />
                      <img src={profileUrl} className="story-profile-img" />
                      <span className="story-avatar-edit-item">
                        <EditFilled
                          style={{ color: "var(--whiteColor)", fontSize: 24 }}
                        />
                      </span>
                    </label>
                  </div>
                ) : (
                  <img
                    src={storyItem.profile_icon}
                    className="story-profile-img"
                  />
                )}
              </div>
              <div className="mt-20">
                <span className="font-14 bold">THE GUARDS</span>
                {storyItem.bodyguard_team.map((x, index) => {
                  return (
                    <div key={index} className="mt-10">
                      <Avatar src={x.bodyguard_icon} size={40} />
                      <span className="ml-10">{x.bodyguard_name}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(DetailModal);
