import React, { useEffect, useState } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import { BackTop } from "antd";
import {
  ROUTE_LOGIN,
  ROUTE_DASHBOARD,
  ROUTE_USER_PROFILE,
  ROOT,
  ROUTE_STORY,
  ROUTE_PIZO,
  ROUTE_ISO_PRAVVISUTI,
  ROUTE_PICCIRIDDI,
  ROUTE_EXTRA,
  ROUTE_CANZONI,
  ROUTE_PROJECT,
} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import DashboardPage from "pages/DashboardPage/Dashboard";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import UserProfilePage from "pages/ProfilePage/UserProfile/UserProfilePage";
import StoryPage from "pages/StoryPage/StoryPage";
import PizoPage from "pages/PizoPage/PizoPage";
import IsoPage from "pages/IsoPage/IsoPage";
import PicPage from "pages/PicPage/PicPage";
import ExtraPage from "pages/ExtraPage/ExtraPage";
import CanzoniPage from "pages/CanzoniPage/CanzoniPage";
import ProjectPage from "pages/ProjectPage/ProjectPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      ChangeSize({ width: screenSize.width, height: screenSize.height })
    );
  }, [screenSize]);

  return (
    <div>
      <Switch>
        {/* <Router basename={"/admin"}>
          <Switch>
            <Route exact path={ROUTE_LOGIN} component={LoginPage} />
          </Switch>
        </Router> */}
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <SidebarLayout>
          <TopbarLayout>
            <MainLayout>
              <Switch>
                <PrivateRoute exact path={ROOT} component={DashboardPage} />
                <PrivateRoute
                  exact
                  path={ROUTE_DASHBOARD}
                  component={DashboardPage}
                />
                <PrivateRoute exact path={ROUTE_STORY} component={StoryPage} />
                <PrivateRoute
                  exact
                  path={ROUTE_CANZONI}
                  component={CanzoniPage}
                />
                <PrivateRoute exact path={ROUTE_PIZO} component={PizoPage} />
                <PrivateRoute
                  exact
                  path={ROUTE_ISO_PRAVVISUTI}
                  component={IsoPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_PICCIRIDDI}
                  component={PicPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_PROJECT}
                  component={ProjectPage}
                />
                <PrivateRoute exact path={ROUTE_EXTRA} component={ExtraPage} />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_PROFILE}
                  component={UserProfilePage}
                />

                {/* List a generic 404-Not Found route here */}
                <Route path="*" component={NotFound} />
              </Switch>
              <BackTop />
            </MainLayout>
          </TopbarLayout>
        </SidebarLayout>
      </Switch>
    </div>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
