import { ReloadOutlined } from "@ant-design/icons";
import { Table, Input, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
const { Search } = Input;
const OneTable = (props) => {
  const { dataList, columns, onClickReload, keyword, setKeyword } = props;
  
  return (
    <div>
      
      <Table
        columns={columns}
        dataSource={dataList}
        scroll={{ x: 900 }}
      />
    </div>
  );
};

export default OneTable;
