import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import "./DetailModal.css";
import { Avatar, Col, Input, message, Row, Spin, Tooltip } from "antd";
import { isEmpty } from "utils/GlobalFunctions";
import { googleKey } from "config/CONSTANTS";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
const { TextArea } = Input;
function DetailModal(props) {
  const { show, proceed, pizzoItem, edit, categoryList } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [stCity, setStCity] = useState("")
  const [onePizzo, setOnePizzo] = useState({
    name: "",
    cat_en: "",
    cat_it: "",
    subcat_en: "",
    subcat_it: "",
    cat_type: "",
    description: "",
    city: "",
    street: "",
    country: "",
    lat: 0,
    lng: 0,
    address: "",
  });

  const [errorField, setErrorField] = useState([]);
  useEffect(() => {
    if (pizzoItem != undefined) {
      setStCity(pizzoItem.location.address.city)
      setOnePizzo({
        ...pizzoItem,
        cat_en: pizzoItem.cat.en,
        cat_it: pizzoItem.cat.it,
        subcat_en: pizzoItem.subcat.en,
        subcat_it: pizzoItem.subcat.it,
        address:
          pizzoItem.location.address.city +
          ", " +
          pizzoItem.location.address.street +
          ", " +
          pizzoItem.location.address.country,
        lat: pizzoItem.location.position.latitude,
        lng: pizzoItem.location.position.longitude,
        city: pizzoItem.location.address.city,
        street: pizzoItem.location.address.street,
        country: pizzoItem.location.address.country,
      });
    }
  }, [pizzoItem]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(onePizzo, "name", errorList);
    errorList = isEmpty(onePizzo, "description", errorList);
    errorList = isEmpty(onePizzo, "cat_en", errorList);
    errorList = isEmpty(onePizzo, "cat_it", errorList);
    errorList = isEmpty(onePizzo, "subcat_en", errorList);
    errorList = isEmpty(onePizzo, "subcat_it", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onUpdate = async () => {
    if (validateFields()) {
      if (onePizzo.address == "") {
        message.error("Please select location");
      } else {
        proceed({...onePizzo, stCity: stCity});
      }
    }
  };
  const onClickPosition = (t, map, coord) => {
    const { lat, lng } = t;
    Geocode.setApiKey(googleKey);
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city = "",
          state = "",
          country = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                setOnePizzo({
                  ...onePizzo,
                  city: city
                });
                setStCity(city)
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                setOnePizzo({
                  ...onePizzo,
                  street: state,
                });
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                setOnePizzo({
                  ...onePizzo,
                  country: country,
                });
                break;
            }
          }
        }
        setOnePizzo({
          ...onePizzo,
          address: address,
          lat: lat,
          lng: lng,
          street: state,
          country: country,
          city: city,
        });
        if (address != "") {
          setStCity(address.split(",")[0])
        }
        
      },
      (error) => {
        console.error(error);
      }
    );
  };
  if (onePizzo == undefined) return <></>;
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={pizzoItem == undefined ? "Add Pizzo Free" : "Update Pizzo Free"}
      className="pizzo-detail-modal"
      okButtonProps={{ disabled: !edit }}
      onOk={() => {
        onUpdate();
      }}
      okText={pizzoItem == undefined ? "Add" : "Update"}
    >
      <Spin spinning={isBusy}>
        <Row gutter={16}>
          <Col sm={24} md={8}>
            <div>
              <span className="font-14 bold">Name</span>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.name}
                  onChange={(e) => {
                    if (errorField.includes("name")) {
                      let errors = errorField.filter((x) => x != "name");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({ ...onePizzo, name: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="mt-10">
              <span className="font-14 bold">Description</span>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("description")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.description}
                  onChange={(e) => {
                    if (errorField.includes("description")) {
                      let errors = errorField.filter((x) => x != "description");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({ ...onePizzo, description: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="mt-10">
              <span className="font-14 bold">Cat Type</span>
              <div className="story-description-rect">
                <ProductTypeSelector
                  field={"category"}
                  productInfo={onePizzo}
                  setProductInfo={setOnePizzo}
                  placeholder={"Select Category"}
                  list={categoryList}
                  errorField={errorField}
                  setErrorField={setErrorField}
                  displayField="name"
                  idField="subcat"
                  isCategory={true}
                />
              </div>
            </div>

            <div className="mt-10">
              <span className="font-14 bold">{`Cat(en)`}</span>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("cat_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.cat_en}
                  onChange={(e) => {
                    if (errorField.includes("cat_en")) {
                      let errors = errorField.filter((x) => x != "cat_en");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({ ...onePizzo, cat_en: e.target.value });
                  }}
                />
              </div>
              <div className="font-14 bold mt-10">{`Cat(it)`}</div>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("cat_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.cat_it}
                  onChange={(e) => {
                    if (errorField.includes("cat_it")) {
                      let errors = errorField.filter((x) => x != "cat_it");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({ ...onePizzo, cat_it: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="mt-10">
              <span className="font-14 bold">{`Sub Cat(en)`}</span>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("subcat_en")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.subcat_en}
                  onChange={(e) => {
                    if (errorField.includes("subcat_en")) {
                      let errors = errorField.filter((x) => x != "subcat_en");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({
                      ...onePizzo,
                      subcat_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="font-14 bold mt-10">{`Sub Cat(it)`}</div>
              <div className="story-description-rect">
                <Input
                  className={
                    errorField.includes("subcat_it")
                      ? `common-input-error`
                      : `common-input`
                  }
                  value={onePizzo.subcat_it}
                  onChange={(e) => {
                    if (errorField.includes("subcat_it")) {
                      let errors = errorField.filter((x) => x != "subcat_it");
                      setErrorField([...errors]);
                    }
                    setOnePizzo({
                      ...onePizzo,
                      subcat_it: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="mt-10">
              <span className="font-14 bold">{`Phone number`}</span>
              <div className="story-description-rect">
                <Input
                  className="common-input"
                  value={onePizzo.phone}
                  onChange={(e) => {
                    setOnePizzo({
                      ...onePizzo,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="font-14 bold mt-10">{`Website`}</div>
              <div className="story-description-rect">
                <Input
                  className="common-input"
                  value={onePizzo.web}
                  onChange={(e) => {
                    setOnePizzo({
                      ...onePizzo,
                      web: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={24} md={16}>
            <div>
              <span className="font-14 bold">{`Location`}</span>
              <div
                style={{
                  width: "100%",
                  height: "57vh",
                  border: "2px solid grey",
                  borderRadius: "10px",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleKey,
                    libraries: ["places"],
                  }}
                  center={{
                    lat: 41.90228792351377,
                    lng: 12.483109935098332,
                  }}
                  onClick={(t, map, coord) => {
                    onClickPosition(t, map, coord);
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  defaultZoom={6}
                >
                  <div
                    lat={parseFloat(onePizzo.lat)}
                    lng={parseFloat(onePizzo.lng)}
                  >
                    <Tooltip placement="top" title={onePizzo.address}>
                      <i
                        className="fas fa-store"
                        style={{
                          cursor: "pointer",
                          color: "red",
                          fontSize: 25,
                        }}
                      ></i>
                    </Tooltip>
                  </div>
                </GoogleMapReact>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(DetailModal);
