import React from 'react';
import "./TopWidget.css"
import { Row, Col } from "antd";

import { ReactComponent as postIcon } from '../../../assets/icons/ic_post.svg';
import { ROUTE_CANZONI, ROUTE_EXTRA, ROUTE_ISO_PRAVVISUTI, ROUTE_PICCIRIDDI, ROUTE_PIZO, ROUTE_STORY } from 'navigation/CONSTANTS';
import CategoryItemWidget from './CategoryItemWidget/CategoryItemWidget';
import { useEffect } from 'react';

const TopWidget = (props) => {
    const { dashboardDetail } = props;
    const { story } = dashboardDetail === undefined ? { story: 0 } : dashboardDetail;
    const { isop } = dashboardDetail === undefined ? { isop: 0 } : dashboardDetail;
    const { ipicciridi } = dashboardDetail === undefined ? { ipicciridi: 0 } : dashboardDetail;
    const { pizzo } = dashboardDetail === undefined ? { pizzo: 0 } : dashboardDetail;
    const { extra } = dashboardDetail === undefined ? { extra: 0 } : dashboardDetail;
    const { canzoni } = dashboardDetail === undefined ? { canzoni: 0 } : dashboardDetail;
    
    return (
        <div>
            <Row align={'middle'} gutter={[32, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active story: "
                        count={story}
                        type="Story"
                        onClick={() => {
                            props.onClick(ROUTE_STORY);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active isop: "
                        count={isop}
                        type="Isop"
                        onClick={() => {
                            props.onClick(ROUTE_ISO_PRAVVISUTI);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active ipicciridi: "
                        count={ipicciridi}
                        type="Ipicciridi"
                        onClick={() => {
                            props.onClick(ROUTE_PICCIRIDDI);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active pizzo-free: "
                        count={pizzo}
                        type="Pizzo Free"
                        onClick={() => {
                            props.onClick(ROUTE_PIZO);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active extra: "
                        count={extra}
                        type="Extra"
                        onClick={() => {
                            props.onClick(ROUTE_EXTRA);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <CategoryItemWidget
                        icon={postIcon}
                        title="Active canzoni: "
                        count={canzoni}
                        type="Canzoni"
                        onClick={() => {
                            props.onClick(ROUTE_CANZONI);
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TopWidget;